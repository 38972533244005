#wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#canvas {
  display: block;
  margin: 0;
  color: white;
}

#canvas:focus {
  outline: none;
}

.godot {
  font-family: "Noto Sans", "Droid Sans", Arial, sans-serif;
  color: #e0e0e0;
  background-color: #3b3943;
  background-image: linear-gradient(to bottom, #403e48, #35333c);
  border: 1px solid #45434e;
  box-shadow: 0 0 1px 1px #2f2d35;
}

/* Status display
		 * ============== */

#status {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* don't consume click events - make children visible explicitly */
  visibility: hidden;
}

#status-progress {
  width: 366px;
  height: 7px;
  background-color: #38363a;
  border: 1px solid #444246;
  padding: 1px;
  box-shadow: 0 0 2px 1px #1b1c22;
  border-radius: 2px;
  visibility: visible;
}

@media only screen and (orientation: portrait) {
  #status-progress {
    width: 61.8%;
  }
}

#status-progress-inner {
  height: 100%;
  width: 0;
  box-sizing: border-box;
  transition: width 0.5s linear;
  background-color: #202020;
  border: 1px solid #222223;
  box-shadow: 0 0 1px 1px #27282e;
  border-radius: 3px;
}

#status-indeterminate {
  visibility: visible;
  position: relative;
}

#status-indeterminate > div {
  width: 4.5px;
  height: 0;
  border-style: solid;
  border-width: 9px 3px 0 3px;
  border-color: #2b2b2b transparent transparent transparent;
  transform-origin: center 21px;
  position: absolute;
  animation: indeterminate-blink 800ms infinite;
}

#status-indeterminate > div:nth-child(1) {
  transform: rotate(22.5deg);
  animation-delay: 0ms;
}
#status-indeterminate > div:nth-child(2) {
  transform: rotate(67.5deg);
  animation-delay: 100ms;
}
#status-indeterminate > div:nth-child(3) {
  transform: rotate(112.5deg);
  animation-delay: 200ms;
}
#status-indeterminate > div:nth-child(4) {
  transform: rotate(157.5deg);
  animation-delay: 300ms;
}
#status-indeterminate > div:nth-child(5) {
  transform: rotate(202.5deg);
  animation-delay: 400ms;
}
#status-indeterminate > div:nth-child(6) {
  transform: rotate(247.5deg);
  animation-delay: 500ms;
}
#status-indeterminate > div:nth-child(7) {
  transform: rotate(292.5deg);
  animation-delay: 600ms;
}
#status-indeterminate > div:nth-child(8) {
  transform: rotate(337.5deg);
  animation-delay: 700ms;
}

@keyframes indeterminate-blink {
  0% {
    border-top-color: #dfdfdf;
  }

  12.5% {
    border-top-color: none;
  }
}

#status-notice {
  margin: 0 100px;
  line-height: 1.3;
  visibility: visible;
  padding: 4px 6px;
  visibility: visible;
}
